<template>
  <AuthContainer @onBack="handleBack" :backFlag="false" title="交易結果">
    <AuthBackContainer :backFlag="false" title="交易結果">
      <article class="h-inherit pt-12 md:h-auto md:pt-0 md:max-w-1200 md:mx-auto">
        <div class="md:bg-white pt-2 min-h-screen h-min-full ">
          <!--結帳-->

          <!--交易失敗-->
          <section class="bg-white mt-1 mb-4">
            <div class="container-wrapper">
              <article class="flex flex-col  items-center">
                <img class="w-1/2 md:w-60" src="../../assets/img/cart/order_error.png" />
                <h6 class="text-brown text-xl md:text-2xl md:mb-2">交易失敗</h6>
              </article>
            </div>
          </section>

          <!--付款總金額-->
          <section class="bg-white mt-3 md:w-1/2 md:mx-auto">
            <div v-if="$route.query.Amount" class="container-wrapper pb-0  justify-center  md:pb-4">
              <div class="flex mb-2 items-center pb-4 border-b">
                <img class="cart-item-img mr-2 " :src="productImage"/>
                <div>
                  <p class="text-brown font-medium md:text-lg">{{productName}}...</p>
                </div>
              </div>
              <div class="flex flex-row justify-center py-2">
                <span class="text-brown text-xl mr-2 md:text-lg">付款總金額</span>
                <span class="text-pink-salmon font-semibold text-xl ml-2 md:text-lg">{{amount}}</span>
              </div>
            </div>

            <div class="flex items-center justify-center m-4 py-2">
              <ul>
                <li>訂單號碼: {{ $route.query.CustomField1 }}</li>
                <li>銀行代碼: {{ $route.query.BankCode }}</li>
                <li>銀行帳號: {{ $route.query.vAccount }}</li>
                <li>截止時間: {{ expireDate }}</li>
              </ul>
            </div>
            <div class="p-4">
              <button class="btn-forms btn-block md:w-1/2 md:mx-auto" @click="handleBack">完成</button>
            </div>
          </section>
        </div>
      </article>
    </AuthBackContainer>
  </AuthContainer>
</template>

<script>
import AuthContainer from '@/components/auth/AuthContainer'
import AuthBackContainer from '@/components/auth/AuthBackContainer'
import { LogEnums } from '@/utils/enums'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'failure',
  components: {
    AuthContainer,
    AuthBackContainer
  },
  data() {
    return {
      imgNotFound: 'this.src="' + require('../../assets/img/status/not_found_img_72x72.jpg') + '"'
    }
  },
  computed: {
    ...mapGetters(['isLogin']),
    expireDate() {
      return decodeURIComponent(this.$route.query.ExpireDate)
    },
    productImage(){
      return decodeURIComponent(this.$route.query.ProductImage)
    },
    productName(){
      return decodeURIComponent(this.$route.query.ProductName)
    },
    amount(){
       return decodeURIComponent(this.$route.query.Amount)
    }
  },
  mounted() {
    this.postLog({
      code: LogEnums.INTO_CHECKOUT_FAILED,
    })
  },
  methods: {  
    ...mapActions(['postLog']),
    handleBack() {
      this.$router.push('/dashboard')
    }
  },
}
</script>
<style lang="postcss" scoped>
</style>
